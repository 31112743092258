var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var Analytics = /** @class */ (function () {
    function Analytics() {
        this.providers = [];
    }
    Analytics.prototype.start = function () {
        this.setupButtonEvents();
        this.trackPageLoad();
    };
    Analytics.prototype.trackPageLoad = function () {
        for (var _i = 0, _a = this.providers; _i < _a.length; _i++) {
            var provider = _a[_i];
            provider.trackPageLoad();
        }
    };
    Analytics.prototype.trackPageView = function () {
        for (var _i = 0, _a = this.providers; _i < _a.length; _i++) {
            var provider = _a[_i];
            provider.trackPageView();
        }
    };
    Analytics.prototype.trackSiteSearch = function (keyword, category, searchCount) {
        for (var _i = 0, _a = this.providers; _i < _a.length; _i++) {
            var provider = _a[_i];
            provider.trackSiteSearch(keyword, category, searchCount);
        }
    };
    Analytics.prototype.trackEvent = function (category, action, name, value) {
        for (var _i = 0, _a = this.providers; _i < _a.length; _i++) {
            var provider = _a[_i];
            provider.trackEvent(category, action, name, value);
        }
    };
    /**
     * Submit analytics events for clickable elements with data-track-event="Cat | Action | Name" attributes.
     */
    Analytics.prototype.setupButtonEvents = function () {
        var _this = this;
        document.addEventListener('click', function (e) {
            if (e.target && e.target instanceof Element) {
                // find the closest element with the data-track-event attribute
                var trackable = e.target.closest('[data-track-event]');
                if (trackable) {
                    var data = trackable.getAttribute('data-track-event');
                    if (data && data.includes('|')) {
                        var _a = data.split('|').map(function (s) { return s.trim(); }), category = _a[0], action = _a[1], name_1 = _a[2];
                        _this.trackEvent(category, action, name_1);
                    }
                }
            }
        });
    };
    return Analytics;
}());
export { Analytics };
var GA4 = /** @class */ (function () {
    function GA4() {
    }
    GA4.prototype.trackPageLoad = function () {
        // tracked automatically
    };
    GA4.prototype.trackPageView = function () {
        // @ts-ignore
        window.gtag('event', 'page_view');
    };
    GA4.prototype.trackEvent = function (category, action, name, value) {
        // @ts-ignore
        window.gtag('event', action, { event_category: category, event_name: name, value: value });
    };
    GA4.prototype.trackSiteSearch = function (keyword, category, searchCount) {
        // @ts-ignore
        window.gtag('event', 'site_search', { keyword: keyword, category: category, searchCount: searchCount });
    };
    return GA4;
}());
export { GA4 };
var Matomo = /** @class */ (function () {
    function Matomo() {
    }
    Matomo.prototype.trackPageLoad = function () {
        // tracked automatically
    };
    Matomo.prototype.trackPageView = function () {
        // @ts-ignore
        window._paq.push(['trackPageView']);
    };
    Matomo.prototype.trackEvent = function (category, action, name, value) {
        // @ts-ignore
        window._paq.push(['trackEvent', category, action, name, value]);
    };
    Matomo.prototype.trackSiteSearch = function (keyword, category, searchCount) {
        // @ts-ignore
        window._paq.push(['trackSiteSearch', keyword, category, searchCount]);
    };
    return Matomo;
}());
export { Matomo };
var CustomerIO = /** @class */ (function () {
    function CustomerIO() {
        var _a;
        // properties pushed with all page events
        this.pageProperties = JSON.parse(((_a = document.getElementById('track-page-properties')) === null || _a === void 0 ? void 0 : _a.innerText) || '{}');
    }
    CustomerIO.prototype.trackPageLoad = function () {
        this.trackPageView();
        // also track a custom event for page load, if specified
        var name = document.body.dataset.trackPageLoad;
        if (name) {
            // @ts-ignore
            window.cioanalytics.track(name, this.pageProperties);
        }
    };
    CustomerIO.prototype.trackPageView = function () {
        // @ts-ignore
        window.cioanalytics.page(this.pageProperties);
    };
    CustomerIO.prototype.trackEvent = function (category, action, name, value) {
        var props = __assign(__assign({}, this.pageProperties), { event_category: category, event_action: action, event_name: name, value: value });
        // @ts-ignore
        window.cioanalytics.track("".concat(category, " ").concat(action), props);
    };
    CustomerIO.prototype.trackSiteSearch = function (keyword, category, searchCount) {
        // TODO
    };
    return CustomerIO;
}());
export { CustomerIO };
var analytics = new Analytics();
if ('gtag' in window) {
    analytics.providers.push(new GA4());
}
if ('_paq' in window) {
    analytics.providers.push(new Matomo());
}
if ('cioanalytics' in window) {
    analytics.providers.push(new CustomerIO());
}
export default analytics;
